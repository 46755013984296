<script setup>
import Sidebar from './components/sidebar.vue'
import Navbar from './components/navbar.vue'
import { useAppStoreHook } from "@/store/modules/app";
import { computed } from 'vue';
const pureApp = useAppStoreHook();

const isCollapse = computed(()=>{
  return pureApp.getSidebar.opened
})

</script>

<template>
  <div class="app-wrapper" :class="isCollapse?'hideSidebar':''">
    <Sidebar class="sidebar-container" />

    <div class="main-container">
      <Navbar />
      
      <div class="content">
        <router-view v-slot="{ Component }">
          <Transition>
            <keep-alive v-if="$route.meta.keepAlive">
              <component :is="Component" :key="$route.path"/>
            </keep-alive>
            <component :is="Component" :key="$route.path" v-else/>
          </Transition>
        </router-view>
      </div>
      
    </div>
  </div>
</template>



<style lang="less" scoped>

@import "@/styles/variables.module.less";

.main-container {
  min-height: 100%;
  transition: margin-left .28s;
  margin-left: @sideBarWidth;
  position: relative;

  .content{
    padding: 10px;
  }
}

.sidebar-container {
  transition: width 0.28s;
  width: @sideBarWidth !important;
  background-color: @menuBg;
  height: 100%;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  // reset element-ui css
  .horizontal-collapse-transition {
    transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
  }

  .scrollbar-wrapper {
    overflow-x: hidden !important;
  }

  .el-scrollbar__bar.is-vertical {
    right: 0px;
  }

  .el-scrollbar {
    height: calc(100% - 50px);
  }

  &.has-logo {
    .el-scrollbar {
      height: calc(100% - 50px);
    }
  }

  .is-horizontal {
    display: none;
  }

  a {
    display: inline-block;
    width: 100%;
    overflow: hidden;
  }

  .svg-icon {
    margin-right: 16px;
  }

  .sub-el-icon {
    margin-right: 12px;
    margin-left: -2px;
  }

  .el-menu {
    border: none;
    height: 100%;
    width: 100% !important;
  }

  // menu hover
  .submenu-title-noDropdown,
  .el-submenu__title {
    &:hover {
      background-color: @menuHover !important;
    }
  }

  .is-active>.el-submenu__title {
    color: @subMenuActiveText !important;
  }

  & .nest-menu .el-submenu>.el-submenu__title,
  & .el-submenu .el-menu-item {
    min-width: @sideBarWidth !important;
    background-color: @subMenuBg !important;

    &:hover {
      background-color: @subMenuHover !important;
    }
  }
}

.hideSidebar {
  .sidebar-container {
    width: 63px !important;
  }

  .main-container {
    margin-left: 63px;
  }

  .submenu-title-noDropdown {
    padding: 0 !important;
    position: relative;

    .el-tooltip {
      padding: 0 !important;

      .svg-icon {
        margin-left: 20px;
      }

      .sub-el-icon {
        margin-left: 19px;
      }
    }
  }

  .el-submenu {
    overflow: hidden;

    &>.el-submenu__title {
      padding: 0 !important;

      .svg-icon {
        margin-left: 20px;
      }

      .sub-el-icon {
        margin-left: 19px;
      }

      .el-submenu__icon-arrow {
        display: none;
      }
    }
  }

  .el-menu--collapse {
    .el-submenu {
      &>.el-submenu__title {
        &>span {
          height: 0;
          width: 0;
          overflow: hidden;
          visibility: hidden;
          display: inline-block;
        }
      }
    }
  }
}

.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.v-enter-active {
  transition: all 0.5s ease-out;
}

.v-leave-active {
  transition: all 0.2s ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
</style>
