
<script setup>

import { useAppStoreHook } from "@/store/modules/app";
import { computed } from "vue";
const pureApp = useAppStoreHook();


const isCollapse = computed(()=>{
  return pureApp.getSidebar.opened
})

const title = '斑猪后台管理系统'

</script>

<template>
  <div class="sidebar-logo-container" >
    <Transition>
      <router-link v-if="isCollapse" key="collapse" class="sidebar-logo-link" to="/">
        <img src="../../assets/logo.png" class="sidebar-logo">
        <h1 class="sidebar-title">{{ title }} </h1>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img src="../../assets/logo.png" class="sidebar-logo">
        <h1 class="sidebar-title">{{ title }} </h1>
      </router-link>
    </Transition>
  </div>
</template>


<style lang="less" scoped>

.v-enter-active {
  transition: opacity 1s;
}

.v-leave-active {
  transition: opacity 0s;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #fff;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #409eff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      margin-left: 12px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }
}
</style>
