import { store } from "@/store";
import { defineStore } from "pinia";
import Cookies from 'js-cookie'

export const useAppStore = defineStore('bz-admin', {
  state: () => ({ 
    sidebar: {
      opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true
    }
  }),
  getters: {
    getSidebar:state => {
      return state.sidebar;
    },
  },
  actions: {
    toggleSideBar() {
      this.sidebar.opened = !this.sidebar.opened

      if (this.sidebar.opened) {
        Cookies.set('sidebarStatus', 1)
      } else {
        Cookies.set('sidebarStatus', 0)
      }
    },
  },
})

export function useAppStoreHook() {
  return useAppStore(store);
}