<script setup>
import { ElMessage } from "element-plus";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import service from "../utils/request";
import url from "../utils/url";

const route = useRoute()
const router = useRouter()

const loading = ref(false)
const passwordType = ref('password')

const loginForm = ref({})

const loginRules = {
  username: [{ required: true, trigger: 'blur' }],
  password: [{ required: true, trigger: 'blur' }]
}

const togglePwd = ()=>{
  passwordType.value = passwordType.value == 'password'?'':'password'
}

const handleLogin = ()=>{
  loading.value = true
  service.post(url.login,{...loginForm.value})
  .then((res) => {
    loading.value = false
    if (res.data.errno == 0) {

      sessionStorage.setItem("banzhuLoginInfo",JSON.stringify(res.data.data));

      ElMessage({
        message: "登录成功",
        showClose: true,
        type: "success",
      });

      if(route.query.redirect){
        router.push(route.query.redirect);
      }else{
        router.push("/");
      }

    } else {
      ElMessage({
        message: res.data.errmsg || res.data.msg,
        showClose: true,
        type: "error",
      });
    }
  }).catch(err=>{
    loading.value = false
    console.log('%c 🍪 err: ', 'font-size:20px;background-color: #4b4b4b;color:#fff;', err);
  })
}

</script>

<template>
  <div class="login-view">
    <el-form :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on" label-position="left">

      <div class="title-container">
        <h3 class="title">斑猪后台管理系统</h3>
      </div>

      <el-form-item prop="username">
        <el-input class="input-dom" v-model="loginForm.username" placeholder="账号" />
      </el-form-item>

      <el-form-item prop="password">
        
        <el-input
          class="input-dom"
          :type="passwordType"
          v-model="loginForm.password"
          placeholder="密码"
          @keyup.enter="handleLogin"
        >
          <template #suffix>
            <el-icon @click.stop="togglePwd" style="cursor: pointer;font-size:18px;user-select:none;">
              <View v-if="passwordType === 'password'" />
              <Hide v-else />
            </el-icon>
          </template>
        </el-input>
      </el-form-item>

      <el-button class="btn-submit" :loading="loading" type="primary" style="width:100%;" @click="handleLogin">登 录</el-button>
    </el-form>
  </div>
</template>

<style lang='less' scoped>

/deep/ input:-webkit-autofill,
/deep/ input:-webkit-autofill:hover,
/deep/ input:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #17233d;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.login-view{
  width: 400px;
  margin: 20vh auto;

  .input-dom{
    height: 40px;
    margin-top: 10px;
    border-radius: 50px;
  }

  .btn-submit{
    height: 40px;
    margin-top: 10px;
  }
}
</style>