import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import login from '../views/login.vue'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import Layout from '@/layout'

export const routes = [
  {
    path: '/login',
    component: login,
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/dashboard'),
        name: 'Dashboard',
        meta: { title: '主页', icon: 'House' }
      }
    ]
  },
  {
    path: '/setting',
    component: Layout,
    redirect: '/setting/banner',
    meta: { title: '主要设置', icon: 'setting' },
    children: [
      {
        path: '/setting/banner',
        component: () => import('@/views/setting/banner'),
        name: 'banner',
        meta: { title: '轮播图管理', icon: 'setting' }
      },
      {
        path: '/setting/advertise',
        component: () => import('@/views/setting/advertise'),
        name: 'advertise',
        meta: { title: '广告管理', icon: 'setting' }
      }, {
        path: '/setting/hobby',
        component: () => import('@/views/setting/hobby'),
        name: 'hobby',
        meta: { title: '兴趣爱好设置', icon: 'setting' }
      },
      {
        path: '/setting/tutorial',
        component: () => import('@/views/setting/tutorial'),
        name: 'tutorial',
        meta: { title: '教程管理', icon: 'setting' }
      },
      // {
      //   path: '/setting/finance',
      //   component: () => import('@/views/setting/finance'),
      //   name: 'finance',
      //   meta: { title: '财务报表', icon: 'setting' }
      // },
      {
        path: '/setting/version',
        component: () => import('@/views/setting/version'),
        name: 'version',
        meta: { title: '版本图', icon: 'setting' }
      },
      {
        path: '/setting/message',
        component: () => import('@/views/setting/message'),
        name: 'message',
        meta: { title: '系统消息', icon: 'setting' }
      },
    ]
  },
  {
    path: '/audit',
    component: Layout,
    redirect: '/audit/reported',
    meta: { title: '审核', icon: 'Edit' },
    children: [
      {
        path: '/audit/reported',
        component: () => import('@/views/audit/reported'),
        name: 'reported',
        meta: { title: '举报审核', icon: 'Edit' }
      },
      {
        path: '/audit/auditShowcase',
        component: () => import('@/views/audit/auditShowcase'),
        name: 'auditShowcase',
        meta: { title: '橱窗审核', icon: 'Edit' }
      },
      {
        path: '/audit/auditVenue',
        component: () => import('@/views/audit/auditVenue'),
        name: 'auditVenue',
        meta: { title: '场馆审核', icon: 'Edit' }
      },
      {
        path: '/audit/auditUser',
        component: () => import('@/views/audit/auditUser'),
        name: 'auditUser',
        meta: { title: '用户管理', icon: 'Edit' }
      },
      {
        path: '/audit/prohibit',
        component: () => import('@/views/audit/prohibit'),
        name: 'prohibit',
        meta: { title: '禁用管理', icon: 'Edit' }
      },
      {
        path: '/audit/auditRealPerson',
        component: () => import('@/views/audit/auditRealPerson'),
        name: 'auditRealPerson',
        meta: { title: '真人审核', icon: 'Edit' }
      },
    ]
  },
  {
    path: '/label',
    component: Layout,
    redirect: '/label/labelManage',
    meta: { title: '标签', icon: 'CollectionTag' },
    children: [
      {
        path: '/label/labelManage',
        component: () => import('@/views/label/labelManage'),
        name: 'labelManage',
        meta: { title: '标签管理', icon: 'CollectionTag' }
      },
      {
        path: '/label/labelBanner',
        component: () => import('@/views/label/labelBanner'),
        name: 'labelBanner',
        meta: { title: '标签轮播图', icon: 'CollectionTag' }
      },
    ]
  },
  {
    path: '/vip',
    component: Layout,
    redirect: '/vip/vipManage',
    meta: { title: '斑猪荟', icon: 'Medal' },
    children: [
      {
        path: '/vip/vipManage',
        component: () => import('@/views/vip/vipManage'),
        name: 'vipManage',
        meta: { title: '会员购买记录', icon: 'Medal' }
      },
      {
        path: '/vip/vipBanner',
        component: () => import('@/views/vip/vipBanner'),
        name: 'vipBanner',
        meta: { title: '斑猪荟广告', icon: 'Medal' }
      },
      {
        path: '/vip/vipPrivilege',
        component: () => import('@/views/vip/vipPrivilege'),
        name: 'vipPrivilege',
        meta: { title: '会员权益', icon: 'Medal' }
      },
    ]
  },
  {
    path: '/merch',
    component: Layout,
    redirect: '/merch/merchManage',
    meta: { title: '商品', icon: 'Goods' },
    children: [
      {
        path: '/merch/merchManage',
        component: () => import('@/views/merch/merchManage'),
        name: 'merchManage',
        meta: { title: '商品管理', icon: 'Goods', keepAlive: true }
      },
      {
        path: '/merch/addMerch',
        component: () => import('@/views/merch/addMerch'),
        name: 'addMerch',
        meta: { title: '上传商品', icon: 'Upload', keepAlive: true }
      },
      {
        path: '/merch/brandManage',
        component: () => import('@/views/merch/brandManage'),
        name: 'brandManage',
        meta: { title: '商家管理', icon: 'OfficeBuilding' }
      },
      {
        path: '/merch/recommendMerch',
        component: () => import('@/views/merch/recommendMerch'),
        name: 'recommendMerch',
        meta: { title: '热门管理', icon: 'Sunny' }
      },
      {
        path: '/merch/wishManage',
        component: () => import('@/views/merch/wishManage'),
        name: 'wishManage',
        meta: { title: '心愿管理', icon: 'Star' }
      },
    ]
  },
  {
    path: '/order',
    component: Layout,
    redirect: '/order/orderManage',
    meta: { title: '订单', icon: 'Document' },
    children: [
      {
        path: '/order/orderManage',
        component: () => import('@/views/order/orderManage'),
        name: 'orderManage',
        meta: { title: '订单管理', icon: 'Document', keepAlive: true }
      },
      {
        path: '/bill/billManage',
        component: () => import('@/views/order/billManage'),
        name: 'billManage',
        meta: { title: '发票管理', icon: 'Document' }
      },
    ]
  },
  {
    path: '/card',
    component: Layout,
    redirect: '/card/template',
    meta: { title: '卡片', icon: 'Picture' },
    children: [
      {
        path: '/card/template',
        component: () => import('@/views/card/template'),
        name: 'template',
        meta: { title: '模板管理', icon: 'Picture' }
      },
      {
        path: '/card/material',
        component: () => import('@/views/card/material'),
        name: 'material',
        meta: { title: '素材管理', icon: 'Picture' }
      }
    ]
  },
  {
    path: '/finance',
    component: Layout,
    redirect: '/finance/record',
    meta: { title: '财务', icon: 'Notebook' },
    children: [
      {
        path: '/finance/record',
        component: () => import('@/views/finance/record'),
        name: 'record',
        meta: { title: '收支流水', icon: 'Notebook' }
      },
      {
        path: '/finance/abnormals',
        component: () => import('@/views/finance/abnormals'),
        name: 'abnormals',
        meta: { title: '收支异常', icon: 'Notebook' }
      }
    ]
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

//拦截未登录情况
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (!sessionStorage.getItem("banzhuLoginInfo")) {

    if (to.path != '/login') {
      return next(`/login?redirect=${to.path}`)
    }
  }
  next()
})

router.afterEach(to => {
  NProgress.done()
})

export default router
